import {provideHttpClient, withFetch} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom, isDevMode, provideZoneChangeDetection} from '@angular/core';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {metaReducers} from '@core/utils/local-storage-sync';
import {provideEffects} from '@ngrx/effects';
import {Action, MetaReducer, provideStore} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {provideCacheableAnimationLoader, provideLottieOptions} from 'ngx-lottie';
import {NgxNotifierService} from 'ngx-notifier';
import {routes} from './app.routes';
import {ChatNotificationsEffects} from './modules/shared/modal-message/chat-notificationState/chat-notifications.effects';
import {ChatNotificationsState, chatNotificationsReducer} from './modules/shared/modal-message/chat-notificationState/chat-notifications.reducer';
import {NotificationsEffects} from './modules/shared/modal-notifications/notificationState/notifications.effects';
import {NotificationsState, notificationsReducer} from './modules/shared/modal-notifications/notificationState/notifications.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    NgxNotifierService,
    importProvidersFrom(),
    provideRouter(routes),
    provideZoneChangeDetection({eventCoalescing: true}),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withFetch()),
    provideStoreDevtools({
      name: 'Mercado-Meet',
      maxAge: 25,
      autoPause: true,
      trace: true,
      traceLimit: 75,
      logOnly: isDevMode() ? true : false,
      connectInZone: true
    }),

    provideStore({
      notifications: notificationsReducer,
      chatNotifications: chatNotificationsReducer,
    }, {
      metaReducers: metaReducers as MetaReducer<{
        notifications: NotificationsState;
        chatNotifications: ChatNotificationsState;
      }, Action<string>>[]
    }),
    provideEffects(
      NotificationsEffects,
      ChatNotificationsEffects
    ),
    /*  provideServiceWorker('ngsw-worker.js', {
         enabled: isDevMode() ? true : false,
         registrationStrategy: 'registerWhenStable:30000'
     }), */
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideCacheableAnimationLoader(),
  ],
};
