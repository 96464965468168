import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },

  {
    path: '',
    loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule)
  }

];
