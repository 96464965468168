import {Action, ActionReducer, MetaReducer} from '@ngrx/store';
import {environment} from 'environments/environment';
import {localStorageSync} from 'ngrx-store-localstorage';

export function localStorageSyncReducer(reducer: ActionReducer<object>): ActionReducer<object> {
  return (state, action) => {
    if (typeof localStorage !== 'undefined') {
      return localStorageSync({
        keys: [
          `${environment.appName}-notifications`,
          `${environment.appName}-chatNotifications`
          /* add new global persistent keys here */
        ],
        rehydrate: true
      })(reducer)(state, action);
    }
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<object, Action<string>>> = [localStorageSyncReducer];